import gql from "graphql-tag";
import vacancyFragment from "./VacancyFragment";

export const GET_INTERVIEWS_QUERY = gql`
  query GetInterviews(
    $page: Int
    $limit: Int
    $search: String
    $status: [String]
  ) {
    interviews: getInterviews(
      page: $page
      limit: $limit
      search: $search
      status: $status
    ) {
      data {
        id
        candidates {
          interviewee {
            id
            fullName
          }
          email
          stages {
            name
            date
            time
            recruters {
              user {
                id
                name
                photo
              }
            }
            observers {
              user {
                id
                name
                photo
              }
            }
            groupBy
            state
          }
          status
          currentStage
        }
        vacancy {
          title
          id
          client {
            name
          }
        }
      }
      pagination {
        total
        page
        limit
        pages
      }
    }
  }
`;

export const GET_VACANCY_BY_ID_QUERY = gql`
  query GetVacancyById($id: ID!, $fromProfile: Boolean) {
    vacancy: getVacancyById(id: $id, fromProfile: $fromProfile) {
      ...${vacancyFragment}
    }
  }
`;

export const GET_INTERVIEW_BY_ID_QUERY = gql`
  query GetInterviewById($id: ID!) {
    interview: getInterviewById(id: $id) {
      id
      candidates {
        interviewee {
          id
          fullName
          email
          photo
          profession
          professionalExperience {
            entity
            description
            office
            startDate
            endDate
          }
        }
        stages {
          name
          date
          duration
          master
          observers {
            user {
              id
              name
              email
              photo
            }
            classification {
              id
              designation
              purpose
              index
              points
              evaluationPoints {
                name
                percentageWeight
                scoring
              }
              percentageWeight
              createdBy
              notes {
                id
                author
                authorName
                text
                date
              }
            }
            comments {
              id
              text
              date
            }
            status
            joined
          }
          recruters {
            user {
              id
              name
              email
              photo
            }
            classification {
              id
              designation
              purpose
              index
              points
              evaluationPoints {
                name
                percentageWeight
                scoring
              }
              percentageWeight
              createdBy
              notes {
                id
                author
                authorName
                text
                date
              }
            }
            comments {
              id
              text
              date
            }
            status
            joined
          }
          state
          time
          groupBy
        }
        currentStage
        salary
        maxSalary
        startedTime
        cancellationReason
        email
        notes {
          id
          author
          authorName
          text
          date
        }
        status
      }
      vacancy {
        id
        title
        client {
          id
          name
        }
      }
      entity {
        name
      }
      address
      telephone
      subject
      message
    }
  }
`;

export const GET_USERS_ENTITY_QUERY = gql`
  query GetUsersEntity {
    users: getUsersEntity {
      id
      email
      name
      office
      photo
    }
  }
`;

export const GET_CLIENTS_ENTITY_QUERY = gql`
  query GetClientsEntity {
    clients: getClientsEntity {
      id
      name
    }
  }
`;

export const GET_VACANCIES_ENTITY_ALL_QUERY = gql`
  query GetVacanciesEntityAll {
    vacancies: getVacanciesEntityAll {
      id
      title
      client {
        id
      }
    }
  }
`;

export const GET_FAVORITE_RATING_QUERY = gql`
  query GetFavoriteRating {
    lists: getFavoriteRating {
      id
      name
      classification {
        designation
        percentageWeight
        points
        evaluationPoints {
          name
          percentageWeight
          scoring
        }
      }
    }
  }
`;
